:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._w-10037{width:100%;}
:root ._h-10037{height:100%;}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._t-0px{top:0px;}
:root ._l-0px{left:0px;}
:root ._bg-c-color-bas1319873852{background-color:var(--c-color-baseWhite);}
:root ._ai-center{align-items:center;}
@media (min-width: 981px){:root:root:root:root:root:root:root ._h-_gtMd_calc100vh-22087814483{height:calc(100vh - 26px);}}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-row{flex-direction:row;}
:root ._gap-10px{gap:10px;}