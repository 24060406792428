/* Make clicks pass-through */

@loading-bar-height: 2.5px;
@primary-color: #4646f5;

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: @primary-color;

  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;

  width: 100%;
  height: @loading-bar-height;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow:
    0 0 10px @primary-color,
    0 0 5px @primary-color;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 11px;
  right: 14px;
}

#nprogress .spinner-icon {
  width: 19px;
  height: 19px;
  box-sizing: border-box;

  border: solid @loading-bar-height transparent;
  border-top-color: @primary-color;
  border-left-color: @primary-color;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
