:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._maw-10037{max-width:100%;}
:root ._jc-flex-start{justify-content:flex-start;}
:root ._ai-center{align-items:center;}
:root ._fd-column{flex-direction:column;}
:root ._ai-stretch{align-items:stretch;}
:root ._ac-stretch{align-content:stretch;}
:root ._jc-center{justify-content:center;}
:root ._w-10037{width:100%;}
:root ._h-30px{height:30px;}
:root ._mt-0px{margin-top:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._fs-1{flex-shrink:1;}
:root ._fg-1{flex-grow:1;}
:root ._zi-2{z-index:2;}
:root ._pos-absolute{position:absolute;}
:root ._l-0px{left:0px;}
:root ._r-0px{right:0px;}
:root ._t-0px{top:0px;}
:root ._b-0px{bottom:0px;}
:root ._fg-8{flex-grow:8;}
:root ._jc-flex-end{justify-content:flex-end;}
:root ._gap-5px{gap:5px;}
:root ._pr-5px{padding-right:5px;}