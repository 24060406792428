@border-default: 1.5px solid #e4e4e4;
.catalog-page {
  .catalog-header {
    padding: 0px 0px 10px 0px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 4px 9px 0px #00000026;
    z-index: 100;
    position: relative;

    .navbar-custom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 45px;
      margin: 0px 12px 0px 12px;

      .navbar-custom {
        &-icon-left {
        }
        &-main-content {
          width: 100%;
          display: flex;
          align-items: center;
          margin-left: 20px;
        }
        &-right-content {
          margin-top: 15px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 15px;
        }
      }
    }

    .icon-min-width {
      min-width: 24px;
    }

    .icon-hand-bag {
      position: relative;
      display: block;
      margin-right: 16px;
      min-width: 24px;

      .count-indicator {
        position: absolute;
        min-height: 16px;
        min-width: 16px;
        top: -5px;
        left: 20px;
      }
    }
  }

  .plain-text-title {
    padding-left: 15px;
    padding-right: 15px;
  }

  .justified-content {
    text-align: justify;
    padding-left: 15px;
    padding-right: 15px;
  }

  .catalog-content {
    .feed-products-grid-item {
      border-left: 1px solid #e9e9ea;
      border-bottom: 1px solid #e9e9ea;
      a {
        text-decoration: none;
        color: black;
      }
      .feed-product-display-img-overlay-wrap {
        img {
          width: 100%;
        }
      }
    }
  }
}
