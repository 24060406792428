:root {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

body {
  margin: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

body > div[id='__next'] {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

@import './nprogress.less';
@import './home.less';
@import './hamburger.less';
@import './components/InfiniteScroll.less';
@import './SupplyRole.less';
