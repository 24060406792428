:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._bg-32BB4635{background-color:#32BB46;}
:root ._ai-center{align-items:center;}
:root ._btlr-40px{border-top-left-radius:40px;}
:root ._bblr-40px{border-bottom-left-radius:40px;}
:root ._btrr-0px{border-top-right-radius:0px;}
:root ._bbrr-0px{border-bottom-right-radius:0px;}
:root ._h-45px{height:45px;}
:root ._pt-10px{padding-top:10px;}
:root ._pb-10px{padding-bottom:10px;}
:root ._pr-15px{padding-right:15px;}
:root ._pl-15px{padding-left:15px;}
:root ._gap-5px{gap:5px;}
@media (hover) {:root  ._bg-0hover-25a13d35:hover{background-color:#25a13d !important;}}
@media (hover) {:root  ._cur-0hover-pointer:hover{cursor:pointer !important;}}
:root ._ai-stretch{align-items:stretch;}
:root ._w-30px{width:30px;}
:root ._h-30px{height:30px;}
:root ._fd-column{flex-direction:column;}
:root ._pr-50px{padding-right:50px;}