.supply-role-popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .supply-icon-warning {
    margin-top: 10px;
    transform: scale(2.7);
    filter: invert(72%) sepia(98%) saturate(1113%) hue-rotate(359deg) brightness(102%)
      contrast(104%);
  }
  .supply-popup-message {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
  }
}
