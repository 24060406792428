.infinite-scroll-empty-icon {
  filter: invert(73%) sepia(3%) saturate(12%) hue-rotate(323deg) brightness(83%) contrast(88%);
}
.infinite-scroll-empty-container {
  display: flex;
  flex-flow: column-reverse;
  row-gap: 8px;
}

.infinite-scroll-container {
  height: calc(100% - 36px);
  border-left: 1px solid #e9e9ea;
}
